import React, { Component } from 'react';

class End extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="end row center">
                <div>
                    <h1>{this.props.node.label}</h1>
                    <p>Erfassen Sie die wirtschaftlich berechtigten Personen im <a href="https://muncca.com/aktienregister/">muncca Aktienregister</a>.</p>
                </div>
            </div>
        );
    }
}

export default End;
