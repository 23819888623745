import React, { Component } from 'react';

class Decision extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const decisions = this.props.node.decisions.map((decision) =>
            <button key={decision.id} onClick={this.props.callback.bind(this, decision.resultId)} className="waves-effect waves-light btn-large grey">
                {decision.label}
            </button>
        );
        return (
            <div className="decision row center">
                <h1>{this.props.node.label}</h1>
                {decisions}
            </div>
        );
    }
}

export default Decision;
