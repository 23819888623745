import React, { Component } from 'react';
import './App.css';
import Decision from './Decision';
import End from './End';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flow: [{
                id: 0,
                label: 'Handelt es sich um ein einstufiges oder mehrstufiges Beteiligungsverhältnis?',
                type: 'DECISION',
                decisions: [{
                    id: 1,
                    label: 'Einstufiges Beteiligungsverhältnis',
                    resultId: 1
                }, {
                    id: 2,
                    label: 'Mehrstufiges Beteiligungsverhältnis',
                    resultId: 2
                }]
            }, {
                id: 1,
                label: 'Formeller Test: Gibt es natürliche Personen, die allein mit mind. 25% des Kapitals oder der Stimmen am Erwerber des Aktienpakets beteiligt sind?',
                type: 'DECISION',
                decisions: [{
                    id: 1,
                    label: 'Ja',
                    resultId: 101
                }, {
                    id: 2,
                    label: 'Nein',
                    resultId: 3
                }]
            }, {
                id: 2,
                label: 'Formeller Test: Gibt es natürliche Personen, die bei Anwendung des Multplikationstests (Gericke & Kuhn, 2015) tatsächlich eine Kontrolle auf den Erwerber des Aktienpakets ausüben?',
                type: 'DECISION',
                decisions: [{
                    id: 1,
                    label: 'Ja',
                    resultId: 101
                }, {
                    id: 2,
                    label: 'Nein',
                    resultId: 3
                }]
            }, {
                id: 3,
                label: 'Materieller Test: Gibt es natürliche Personen, die auf andere erkennbare Weise faktisch eine zwischengeschaltete Gesellschaft kontrollieren?',
                type: 'DECISION',
                decisions: [{
                    id: 1,
                    label: 'Ja',
                    resultId: 101
                }, {
                    id: 2,
                    label: 'Nein',
                    resultId: 102
                }]
            }, {
                id: 101,
                label: 'Diese Personen sind zu melden und die Prüfung ist abgeschlossen.',
                type: 'END'
            }, {
                id: 102,
                label: 'Falls keine natürlichen Personen im Sinne der vorstehenden Ziffern 1 bis 2 festgestellt werden können, ist ersatzweise die Identität des obersten Mitglieds des leitenden Organs des Targets festzustellen und als wirtschaftlich berechtigte Person zu melden. Dies ist in der Regel der CEO und wo kein solcher vorhanden ist, der Verwaltungsratspräsident.',
                type: 'END'
            }],
            current: undefined
        };
    }
    componentWillMount() {
        this.setState({current: this.state.flow[0]});
    }
    callback(resultId) {
        var node = this.state.flow.find(function(element) {
            return element.id === resultId;
        });
        this.setState({current: node});
    }
    render() {
        return (
            <div className="container">
                {
                    this.state.current.type ==='DECISION' ?
                        <Decision node={this.state.current} callback={this.callback.bind(this)}></Decision> :
                        <End node={this.state.current}></End>
                }
            </div>
        );
    }
}

export default App;
